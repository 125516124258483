import {
  ViewInAr,
  PieChart,
  Code,
  BarChart,
  CloudOutlined,
  FavoriteBorder,
  Public,
  PersonOutlined,
  AddLocationAltOutlined,
  PhoneIphone,
  EmailOutlined,
  Facebook,
  Twitter,
  Instagram,
  LinkedIn,
  Storage,
  Javascript,
} from "@mui/icons-material";

export const navlink = [
  {
    url: "/",
    text: "Home",
  },
  {
    url: "/about",
    text: "About",
  },
  {
    url: "/services",
    text: "Our Mission",
  },
  {
    url: "/portfolio",
    text: "Gigs",
  },
  {
    url: "/testimonials",
    text: "Reviews",
  },
  {
    url: "/blog",
    text: "Founders",
  },
  {
    url: "/contact",
    text: "Contact",
  },
];
export const home = [
  {
    text: "BCB Labs",
    name: "Welcome to BCB Labs",
    post: "Lets build your dream",
    design: "",
    desc: "We are a software development company focused on creating stunning and complex mobile applications. Our expertise lies in developing innovative solutions that cater to the unique needs of our clients. With our flagship app, Gigs, we aim to revolutionize the way musicians find and apply to gigs offered by local venues.",
  },
];
export const about = [
  {
    cover: "./images/BCB.png",
    desc: "At BCB Labs, we specialize in developing visually appealing and feature-rich mobile applications. Our team of skilled developers and designers work together to create stunning and complex apps that cater to the unique needs of our clients. With our expertise in mobile app development, we ensure that every app we create is user-friendly, intuitive, and delivers an exceptional user experience.",
    desc1:
      "BCB Labs is a leading software development company that specializes in creating stunning and complex mobile applications. With a team of highly skilled developers and designers, we strive to deliver exceptional solutions that exceed our clients' expectations. Our flagship app, Gigs, is designed to revolutionize the way musicians find and apply for gigs. We are passionate about technology and dedicated to creating innovative solutions that make a difference in the world.",
  },
];
export const services = [
  {
    id: 1,
    icon: <Javascript />,
    title: "Industry-Leading Expertise",
    desc: "With years of experience in mobile application development, we leverage cutting-edge technologies to bring your ideas to life.",
  },
  {
    id: 2,
    icon: <Storage />,
    title: "Client-Centric Approach",
    desc: "Your vision is our mission. We prioritize open communication and customized solutions to meet each client's unique needs.",
  },
  {
    id: 3,
    icon: <ViewInAr />,
    title: "End-to-End Solutions",
    desc: "From concept to deployment, we offer comprehensive services that cover every aspect of mobile app development, ensuring a seamless process.",
  },
  {
    id: 4,
    icon: <PieChart />,
    title: "Quality Assurance",
    desc: "Rigorous testing protocols are at the heart of our operations. We guarantee robust, scalable, and bug-free applications that stand the test of time.",
  },
  {
    id: 5,
    icon: <Code />,
    title: "Affordable Excellence",
    desc: "Premium quality doesn't have to break the bank—our competitive pricing ensures you get top-notch solutions that maximize ROI.",
  },
  {
    id: 6,
    icon: <BarChart />,
    title: "Post-Launch Support",
    desc: "Our relationship doesn't end at launch. We offer ongoing support and updates, ensuring your app continues to perform optimally.",
  },
];
export const project = [
  {
    id: 1,
    icon: <CloudOutlined />,
    num: "60",
    title: "DAY TURN AROUND ON MOST APPS",
  },
  {
    id: 2,
    icon: <FavoriteBorder />,
    num: "6",
    title: "MONTHS POST-LAUNCH SUPPORT MINIMUM",
  },
  {
    id: 3,
    icon: <Public />,
    num: "24",
    title: "HOUR SUPPORT",
  },
  {
    id: 4,
    icon: <PersonOutlined />,
    num: "2",
    title: "BI-LINGUAL ENGLISH AND SPANISH",
  },
];
export const portfolio = [
  {
    id: 1,
    cover: "../images/GIGSLOGO.png",
    name: "Gigs",
    category: "mobile",
    title: "Opportunity in your pocket",
  },
];
export const testimonials = [
  {
    id: 1,
    text: "",
    image: "./images/mediamodifier_image-15.png",
    image2: "./images/mediamodifier_image-2.png",
    image3: "./images/mediamodifier_image-3.png",
    // image4: "./images/modal.png",
  },
  {
    id: 2,
    text: "",
    image: "./images/mediamodifier_image-4.png",
    image2: "./images/mediamodifier_image-5.png",
    image3: "./images/mediamodifier_image-6.png",
    // image4: "./images/apply.png",
  },
  {
    id: 3,
    text: "",
    image: "./images/mediamodifier_image.png",
    image2: "./images/mediamodifier_image-16.png",
    image3: "./images/mediamodifier_image-9.png",
    // image4: "./images/apply.png",
  },
  {
    id: 3,
    text: "",
    image: "./images/mediamodifier_image-10.png",
    image2: "./images/mediamodifier_image-11.png",
    image3: "./images/mediamodifier_image-12.png",
    // image4: "./images/apply.png",
  },
  {
    id: 4,
    text: "",
    image: "./images/mediamodifier_image-13.png",
    image2: "./images/mediamodifier_image-14.png",
    image3: "./images/mediamodifier_image-15.png",
    // image4: "./images/apply.png",
  },
];
export const blog = [
  {
    id: 1,
    title: "Benjamin Monk",
    // date: "Lorem ipsum",
    // author: "",
    // desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    // cover: "./images/Me.jpeg",
  },
  {
    id: 2,
    title: "Cesar Bacaro",
    // date: "Lorem ipsum",
    // author: "",
    // desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    // cover: "./images/Me.jpeg",
  },
  {
    id: 3,
    title: "Brent Purks",
    // date: "Lorem ipsum",
    // author: "",
    // desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    // cover: "./images/Me.jpeg",
  },
];
export const contact = [
  {
    icon: <AddLocationAltOutlined />,
    text1: "Houston, Tx",
    text2: "USA",
  },
  {
    icon: <PhoneIphone />,
    text1: "(770) 203 9091",
  },
  {
    icon: <EmailOutlined />,
    text1: "brentpurks1@icloud.com",
  },
];
export const social = [
  {
    icon: <Facebook />,
  },
  {
    icon: <Twitter />,
  },
  {
    icon: <Instagram />,
  },
];
