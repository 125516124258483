import React, { useState } from "react";
import { Heading } from "../common/Heading";
import { portfolio } from "../data/dummydata";

// const allCategory = ["all", ...new Set(portfolio.map((item) => item.category))];
export const Portfolio = () => {
  const [list, setLists] = useState(portfolio);
  // const [category, setCategory] = useState(allCategory);
  // console.log(setCategory);

  // const filterItems = (category) => {
  //   const newItems = portfolio.filter((item) => item.category === category);
  //   setLists(newItems);
  //   if (category === "all") {
  //     setLists(portfolio);
  //     return;
  //   }
  // };

  return (
    <>
      <article>
        <div className="container">
          <Heading title="Introducing Gigs" />
          <div className="catButton"></div>
          <div className="content grid3">
            {list.map((item) => (
              <div className="box" data-aos="fade-up" key={item.id}>
                <div className="img">
                  <img src={item.cover} alt="" />
                </div>
                <h3>Giigs</h3>
                <p>
                  Giigs, the revolutionary app designed to simplify the lives of
                  musicians and venues alike. This groundbreaking platform
                  serves as a nexus where talent and opportunity meet,
                  streamlining the process of hiring or being hired. Musicians
                  can effortlessly showcase their availability and "open to
                  work" schedules, making it easier than ever for venues to find
                  the perfect act for their next event.
                </p>
                <p>
                  Giigs is more than just a booking app—it's a community.
                  Whether you're a solo musician or a venue owner, Giigs offers
                  a space where you can connect, collaborate, and create
                  unforgettable experiences. With user-friendly features and a
                  seamless interface, Giigs takes the hassle out of coordinating
                  gigs, so you can focus on what really matters: the music. Say
                  goodbye to endless emails and phone calls. With Giigs, your
                  next performance is just a tap away. Get hired today, and take
                  your band to the next level
                </p>
                <div className="overlay">
                  <h3>{item.title}</h3>
                  <span>{item.name}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </article>
    </>
  );
};
